var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('jaya-connection-layout',{attrs:{"text":"Renseignez votre nouveau mot de passe"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.resetPassword)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Mot de passe et Confirmation"}},[_c('validation-provider',{attrs:{"immediate":"","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"LockIcon"}})],1),_c('b-form-input',{attrs:{"type":_vm.passwordFieldType,"placeholder":"Mot de passe"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('b-form-text',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"immediate":"","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"mt-50"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1),_c('b-form-input',{attrs:{"type":_vm.passwordFieldType,"placeholder":"Confirmation"},model:{value:(_vm.password_confirm),callback:function ($$v) {_vm.password_confirm=$$v},expression:"password_confirm"}})],1),_c('b-form-text',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.resetErrors.password === 'tooShort')?_c('b-form-text',{staticClass:"text-danger"},[_vm._v(" Votre nouveau mot de passe doit contenir au moins 8 caractères dont une lettre et un chiffre ")]):_vm._e(),(_vm.resetErrors.password === 'tooCommonPassword')?_c('b-form-text',{staticClass:"text-danger"},[_vm._v(" Votre mot de passe est trop simple. ")]):_vm._e(),(_vm.resetErrors.password === 'numeric')?_c('b-form-text',{staticClass:"text-danger"},[_vm._v(" Votre mot de passe ne peut pas être composé exclusivement de chiffres. ")]):_vm._e(),(_vm.resetErrors.password === 'tooSimilar')?_c('b-form-text',{staticClass:"text-danger"},[_vm._v(" Votre mot de passe est trop proche de votre nom d'utilisateur ou email. ")]):_vm._e(),(_vm.resetErrors.password === 'badPassword')?_c('b-form-text',{staticClass:"text-danger"},[_vm._v(" Votre mot de passe ne respecte pas notre politique de sécurité, veuillez en essayer un autre, de plus de 8 caractères dont au moins une lettre et un chiffre ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{staticClass:"d-flex justify-content-around flex-wrap mb-1",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"m-25 flex-grow-1",attrs:{"to":"/login","variant":"outline-primary"}},[_vm._v(" Retour au login ")]),_c('b-button',{staticClass:"m-25 flex-grow-1",attrs:{"disabled":invalid,"type":"submit","variant":"primary"}},[_vm._v(" Réinitialiser le mot de passe ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }